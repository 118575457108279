import React from "react";
import { Router, Switch,Route } from "react-router-dom";
// import { Container } from "reactstrap";

import PrivateRoute from "./components/PrivateRoute";
import LoadingInsights from "./components/LoadingInsights";
import Error from "./views/Pages/Error/Error";

import { useAuth0 } from "./react-auth0-spa";

import ReactGA from 'react-ga';

import DefaultLayout from './containers/DefaultLayout';

import './App.scss';


import { createBrowserHistory } from 'history';

import initFontAwesome from "./utils/initFontAwesome";


const history = createBrowserHistory();

// fontawesome


require('dotenv').config();

initFontAwesome();

history.listen(location => {

    let page_title;
    if (location.state){
        if ( location.state.pbi_report_type && location.state.pbi_report_type !== 'BASKET REPORTS')
            page_title = location.state.pbi_report_type;
        else if (location.state.pbi_page_name)
            page_title = location.state.pbi_page_name;
        else
            page_title = location.state.name



        ReactGA.set({ page_title: page_title }); // Update the user's current page
        ReactGA.pageview(location.pathname); // Record a pageview for the given page

    }
});




const App = () => {
  const { loading,user } = useAuth0();


  if (user){

      const trackingId = "UA-55537721-1"; //
      ReactGA.initialize(trackingId);
      ReactGA.set({
          userId: user.sub,
      })


  }

  if (loading) {
    return <LoadingInsights />;
  }

  return (
    <div id="app">
      <Router history={history}>
          <Switch>
            {/*<PrivateRoute path="/profile" component={Profile} />*/}
            <Route path="/error"  component={Error}/>} />
            <PrivateRoute  render={props => <DefaultLayout user={user} {...props}/>} />

          </Switch>
      </Router>
    </div>
  );
};

export default App;
