import React, { Component, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import * as router from 'react-router-dom';
import { Container} from 'reactstrap';
import { restdb } from '../../helper';

import ScreenTypeEnum from '../../utils/ScreenTypeEnum'

import ReactGA from 'react-ga';

import {
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
} from '@coreui/react';

import  AppSidebarNav2 from '../../components/SidebarNav2'

// routes config
import routes from '../../routes';

const DefaultFooter = React.lazy(() => import('./DefaultFooter'));
const DefaultHeader = React.lazy(() => import('./DefaultHeader'));



class DefaultLayout extends Component {

  constructor(props) {

    super(props);

    this.state = {
      isLoadingNavData: true,
      routes: [],
      possibleRoutes: ['/home','/reports/saved/powerbi','/reports/saved/report','/report','/reports/create/report','/campaigns/previous/performance','/basket/create','/basket/edit'
                         ,'/alc/alert/edit','/ce/alert/edit', '/alert/edit','/alc/scheduled-report/edit', '/ce/scheduled-report/edit','/reports/saved/report/:id'],
      navData: {
        items: []
      }

    };

      this.changeDashboard = this.changeDashboard.bind(this)
  }




   redirectHome(navItem){

      if (localStorage.getItem('targetUrl')){
          let targetUrl = localStorage.getItem('targetUrl');
          localStorage.removeItem('targetUrl')
          this.props.history.push({
              pathname: targetUrl
          });
      }
      else if (this.state.dashboard ){
        this.props.history.push({
            pathname: '/report',
            state: {
                pbi_group_id:this.state.dashboard.pbi_group_id,
                pbi_report_id:this.state.dashboard.pbi_report_id,
                user: this.props.user,
                pbi_page_name: this.state.dashboard.bookmark_name,
                pbi_bookmark_state:this.state.dashboard.state,
                pbi_report_type: this.state.dashboard.report_type,
                description: this.state.dashboard.description

            }
        });
    }else{
        if (navItem.url !== '/report'){
            this.props.history.push({
                pathname: navItem.url,
                state: {
                    parent:navItem.parent,
                    description: navItem.description
                }
            });

        }
        else {
            this.props.history.push({
                pathname: navItem.url,
                state: {
                    parent: navItem.parent,
                    // pbi_credentials: navItem.powerbi.pbi_credentials,
                    pbi_group_id: navItem.powerbi.pbi_group_id,
                    pbi_report_id: navItem.powerbi.pbi_report_id,
                    pbi_page_section: navItem.powerbi.pbi_page_section,
                    pbi_page_name: navItem.powerbi.pbi_page_name,
                    pbi_dataset_id: navItem.powerbi.pbi_dataset_id,
                    pbi_export: navItem.powerbi.pbi_export,
                    user: this.props.user
                }
            });
        }
    }

  }


    componentDidUpdate(){

          if (this.props.location.pathname === "/" && this.state.navData.items[0]){
       if (this.state.navData.items[0].children){
           this.redirectHome(this.state.navData.items[0].children[0])
       }else{
           this.redirectHome(this.state.navData.items[0])
       }
   }
    }

  async componentWillMount() {

    const response = await restdb.get("/rest/navigation?sort=order");
    const data = await response.data;

    const responseBookmarks = await restdb.get(`/rest/bookmark?metafields=true&q={"user_email": "`+this.props.user.email+`" }&sort=bookmark_name`);
      //
    let dataDashboard = responseBookmarks.data;

    let dashboard = dataDashboard.filter(bookmark => bookmark.dashboard);

    this.setState({navigation:data, dashboard:dashboard[0]})

    this.getNavData(Object.values(data));

    this.filterRoutes();

    if (this.props.location.pathname === "/" && this.state.navData.items[0]){
      if (this.state.navData.items[0].children){
        this.redirectHome(this.state.navData.items[0].children[0])
      }else{
        this.redirectHome(this.state.navData.items[0])
      }
    }

      ReactGA.event({
          category: 'engagement',
          action: 'login',
          label: '',
          description:'Logging In'
      });


  }

    changeDashboard(report,checked) {

       if (!checked){
           let navData = this.state.navData

           navData['items'] = navData['items'].filter((nav)=> nav.name !== 'Dashboard');

           this.setState({
               dashboard: {}
               , navData:navData
           })
           return;

       }

       let dashboardMenu = false;

           this.state.navData.items.map(item=> {

               if (item.name === 'Dashboard'){
                   dashboardMenu = true;
                   item.powerbi.pbi_group_id = report.pbi_group_id;
                   item.powerbi.pbi_report_id = report.pbi_report_id;
                   item.powerbi.pbi_page_name = report.bookmark_name;
                   item.powerbi.pbi_bookmark_state = report.state;
                   item.powerbi.pbi_report_type = report.report_type;

               }
               return item;
           })

        let navData = this.state.navData

        if (!dashboardMenu)
        {
            let navDataItems =  [ {
                name: 'Dashboard',
                url: '/report',
                icon: 'fa fa-tachometer',
                screen_type: 'POWERBI',
                description:'The Dashboard is configured from your Saved Reports.',
                powerbi: {
                    pbi_group_id:report.pbi_group_id,
                    pbi_report_id:report.pbi_report_id,
                    user: this.props.user,
                    pbi_page_name: report.bookmark_name,
                    pbi_bookmark_state:report.state,
                    pbi_report_type: report.report_type,
                }}].concat(this.state.navData.items)

            navData.items = navDataItems
        }


        this.setState({
            dashboard: report
            , navData:navData
        })

    }

  filterRoutes(){

    let filteredRoutes = [];
    let possibleRoutes = this.state.possibleRoutes;

    routes.forEach(function(route) {

      if (possibleRoutes.includes(route.path))
        filteredRoutes.push(route)

    });

    this.setState({routes:filteredRoutes})
  }

  getNavData(navigation){
    let navData = {};
    let item = {};

    let possibleRoutes = this.state.possibleRoutes;

    navData.items = [];

    if (this.state.dashboard)
        navData.items.push( {
            name: 'Dashboard',
            url: '/report',
            icon: 'fa fa-tachometer',
            screen_type: 'POWERBI',
            description:'The Dashboard is configured from your Saved Reports.',
            powerbi: {
                pbi_group_id:this.state.dashboard.pbi_group_id,
                pbi_report_id:this.state.dashboard.pbi_report_id,
                user: this.props.user,
                pbi_page_name: this.state.dashboard.bookmark_name,
                pbi_bookmark_state:this.state.dashboard.state,
                pbi_report_type: this.state.dashboard.report_type,
            }});

    let nav = navigation.filter(nav => (nav.screen_type ===ScreenTypeEnum.PARENT
      || (nav.parent ==='' && nav.screen_type === ScreenTypeEnum.POWERBI)
      || (nav.parent ==='' && nav.screen_type === ScreenTypeEnum.CUSTOM) ));

    let userPermissions =this.props.user['https://invigorinsights.com/permissions']

    nav.forEach(function(parent) {

      if (   userPermissions.includes(parent.permission) )
      {
        if (parent.parent ==='' && parent.screen_type !== ScreenTypeEnum.PARENT){

          if (parent.screen_type === ScreenTypeEnum.CUSTOM) {
            item = {
              name: parent.name,
              url: parent.custom_route,
              screen_type: parent.screen_type,
              icon: 'fa ' + parent.icon,
              description: parent.description,
              powerbi: {
                pbi_group_id: parent.pbi_group_id,
                pbi_report_id: parent.pbi_report_id,
                pbi_page_name: parent.pbi_page_name,
                pbi_export:parent.pbi_export,

                pbi_page_section: parent.pbi_page_section,

                pbi_report_height: parent.pbi_report_height,

                  pbi_dataset_id: parent.pbi_dataset_id

              }
            }
            possibleRoutes.push(parent.custom_route)
          } else if (parent.screen_type === ScreenTypeEnum.POWERBI && parent.name === 'Create Basket') {
              item = {
                  name: parent.name,
                  url: '/basket/create',
                  icon: 'fa ' + parent.icon,
                  screen_type: parent.screen_type,
                  description: parent.description,
                  vertical: parent.vertical,
                  powerbi: {
                      pbi_group_id: parent.pbi_group_id,
                      pbi_report_id: parent.pbi_report_id,
                      pbi_page_name: parent.pbi_page_name,
                      pbi_export: parent.pbi_export,

                      pbi_page_section: parent.pbi_page_section,

                      pbi_report_height: parent.pbi_report_height,

                      pbi_dataset_id: parent.pbi_dataset_id

                  }

              }

          } else if (parent.screen_type === ScreenTypeEnum.POWERBI) {
              item = {
                  name: parent.name,
                  url: '/report',
                  icon: 'fa ' + parent.icon,
                  screen_type: parent.screen_type,
                  description:parent.description,
                  vertical: parent.vertical,
                  powerbi: {
                      pbi_group_id: parent.pbi_group_id,
                      pbi_report_id: parent.pbi_report_id,
                      pbi_page_name: parent.pbi_page_name,
                      pbi_export: parent.pbi_export,

                      pbi_page_section: parent.pbi_page_section,

                      pbi_report_height: parent.pbi_report_height,

                      pbi_dataset_id: parent.pbi_dataset_id
                  }

              }
          }

        } else{

          item = {
            name: parent.name,
            url: parent.custom_route ? parent.custom_route : '#',
            icon: 'fa ' + parent.icon,
            children: [ ],
            description: parent.description,
            screen_type: parent.screen_type,
            vertical: parent.vertical,
            powerbi: {
              pbi_group_id: parent.pbi_group_id,
              pbi_report_id: parent.pbi_report_id,
              pbi_page_name: parent.pbi_page_name,
              pbi_export: parent.pbi_export,

              pbi_page_section: parent.pbi_page_section,

              pbi_report_height: parent.pbi_report_height,

                pbi_dataset_id: parent.pbi_dataset_id
            }
          }


          let childrens = navigation.filter(nav => nav.parent ===parent.name
              && nav.vertical === parent.vertical
          );

          childrens.forEach(function(children) {


            if (   userPermissions.includes(children.permission) ) {

              if (children.screen_type === ScreenTypeEnum.CUSTOM) {
                item.children.push({
                  name: children.name,
                  url: children.custom_route,
                  icon: 'fa ' + children.icon,
                  description: children.description,
                  parent:children.parent,
                  permission: children.permission,
                  screen_type: children.screen_type,
                  vertical: children.vertical,
                  powerbi: {
                    pbi_group_id: children.pbi_group_id,
                    pbi_report_id: children.pbi_report_id,
                    pbi_page_name: children.pbi_page_name,

                    pbi_export: children.pbi_export,
                    pbi_page_section: children.pbi_page_section,
                    pbi_report_height: children.pbi_report_height,

                      pbi_dataset_id: children.pbi_dataset_id
                  }
                });
                possibleRoutes.push(children.custom_route)
              } else if (children.screen_type ===  ScreenTypeEnum.POWERBI && children.name === 'Create Basket') {

                  item.children.push({
                      name: children.name,
                      url: '/basket/create',
                      icon: 'fa ' + children.icon,
                      parent:children.parent,
                      permission:children.permission,
                      screen_type: children.screen_type,
                      description: children.description,
                      vertical: children.vertical,
                      powerbi: {
                          pbi_group_id: children.pbi_group_id,
                          pbi_report_id: children.pbi_report_id,
                          pbi_page_name: children.pbi_page_name,
                          pbi_page_section: children.pbi_page_section,
                          pbi_report_height: children.pbi_report_height,
                          pbi_dataset_id: children.pbi_dataset_id
                      }
                  });

              } else if (children.screen_type ===  ScreenTypeEnum.POWERBI) {

                item.children.push({
                  name: children.name,
                  url: '/report',
                  icon: 'fa ' + children.icon,
                  parent:children.parent,
                  permission:children.permission,
                  screen_type: children.screen_type,
                  description:children.description,
                  vertical: children.vertical,
                  powerbi: {
                    pbi_group_id: children.pbi_group_id,
                    pbi_report_id: children.pbi_report_id,
                    pbi_page_name: children.pbi_page_name,
                    pbi_export: children.pbi_export,
                    pbi_page_section: children.pbi_page_section,
                    pbi_report_height: children.pbi_report_height,
                      pbi_dataset_id: children.pbi_dataset_id
                  }
                });
              }
            }


          });

        }
        navData.items.push(item);
      }

    });


    this.setState({navData: navData,possibleRoutes:possibleRoutes
      ,isLoadingNavData:false})

  }

  loading = () => <div className="animated fadeIn pt-1 text-center">
    Loading...
  </div>

  askForPermission = () => (
    <div className="animated fadeIn pt-1 text-center" style={{ fontSize: '20px' }}>
      Please contact <a href="mailto:support@invigor.io">Invigor Support</a> to check your access.
    </div>
  );



  signOut(e) {
    e.preventDefault()
    this.props.history.push('/login')
  }




  render() {

    return (

      <div className="app">
        <AppHeader fixed>
          <Suspense  fallback={this.loading()}>
            <DefaultHeader onLogout={e=>this.signOut(e)}/>
          </Suspense>
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <Suspense>
              <AppSidebarNav2 navConfig={this.state.navData} isLoadingNavData={this.state.isLoadingNavData} {...this.props} router={router}/>
            </Suspense>
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>
          <main className="main">
            <Container fluid>
              <Suspense fallback={this.loading()}>
              {this.state.navData['items'].length === 0 && this.state.isLoadingNavData === false? (
                this.askForPermission()
              ) : (
                <Switch>
                  {this.state.routes.map((route, idx) => {
                    return route.component ? (
                      <Route
                        key={idx}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={props => (
                          <route.component changeDashboard={this.changeDashboard} user={this.props.user} {...props}/>
                        )}
                      />
                    ) : null;
                  })}
                </Switch>
              )} 
              </Suspense>
            </Container>
          </main>
        </div>
        <AppFooter>
          <Suspense fallback={this.loading()}>
            <DefaultFooter />
          </Suspense>
        </AppFooter>
      </div>
    );
  }
}

export default DefaultLayout;

