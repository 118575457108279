import React, {Component, Suspense} from 'react';
import {  Col, Container, Row } from 'reactstrap';

import logo from '../../../assets/img/brand/logo.png'

class Error extends Component {

  loading = () => <div className="animated fadeIn pt-1 text-center">
    Loading...
  </div>

  render() {
    return (
      <Suspense  fallback={this.loading()}>
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <a href="https://insights.invigor.io">
            <img src={logo} alt="Invigor Insights" />
            </a>
          </Row>
          <Row className="justify-content-center">
            <Col md="6">
              <div className="clearfix">
                {/*<h1 className="float-left display-3 mr-4">OPS</h1>*/}
                <i className="float-left display-3 mr-4 fa fa-exclamation-circle mainColor" > </i>
                <h4 className="pt-3">Oops! It looks like something went wrong, but don't panic.</h4>
                <p className="text-muted float-left">Please click <a href="https://insights.invigor.io">here</a> to try reloading Invigor Insights.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      </Suspense>
    );
  }
}

export default Error;
