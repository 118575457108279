import React from 'react';

const CreateReport = React.lazy(() => import('./views/Pages/Reports/CreateReport'));
const SavedReports = React.lazy(() => import('./views/Pages/Reports/SavedReports'));
const Home = React.lazy(() => import('./views/Home'));

const Alerts = React.lazy(() => import('./views/Pages/Alerts/Alerts'));
const AlertComponent = React.lazy(() => import('./views/Pages/Alerts/Alert'));
const ScheduleAlerts = React.lazy(() => import('./views/Pages/Alerts/ScheduleAlerts'));

const ScheduledReports = React.lazy(() => import('./views/Pages/ScheduledReports/ScheduledReports'));
const ScheduledReport = React.lazy(() => import('./views/Pages/ScheduledReports/ScheduledReport'));

const Tutorials = React.lazy(() => import('./views/Pages/Tutorials/Tutorials'));

const CreateCampaign = React.lazy(() => import('./views/Pages/Campaigns/CreateCampaign'));
const Campaigns = React.lazy(() => import('./views/Pages/Campaigns/Campaigns'));

const CreateCampaignDemo = React.lazy(() => import('./views/Pages/Campaigns/CreateCampaignDemo'));
const CampaignsDemo = React.lazy(() => import('./views/Pages/Campaigns/CampaignsDemo'));


const PowerBI = React.lazy(() => import('./views/PowerBI/PowerBI'));
const CreateBasket = React.lazy(() => import('./views/Pages/Baskets/CreateBasket'));
const SavedBaskets = React.lazy(() => import('./views/Pages/Baskets/SavedBaskets'));
const EditBasket = React.lazy(() => import('./views/Pages/Baskets/EditBasket'));


const Tokens = React.lazy(() => import('./views/Pages/API/Tokens'));
const APIDocumentation = React.lazy(() => import('./views/Pages/API/APIDocumentation'));



const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/reports/create'
    , exact: true, name: 'Create Report  '
    , component: CreateReport },

  { path: '/reports/saved'
    , exact: true, name: 'Saved Reports'
    , component: SavedReports },


    { path: '/baskets/saved'
        , exact: true, name: 'Saved Baskets'
        , component: SavedBaskets },

    { path: '/alc/scheduled-reports/manage'
        , exact: true
        , name: 'Scheduled Reports'
        , component: ScheduledReports
        , vertical:'ALC'},

    { path: '/alc/tutorials'
        , exact: true
        , name: 'Tutorials'
        , component: Tutorials
        , vertical:'ALC'},

    { path: '/ce/tutorials'
        , exact: true
        , name: 'Tutorials'
        , component: Tutorials
        , vertical:'CE'},

    { path: '/alc/scheduled-reports/create'
        , exact: true
        , name: 'Create Scheduled Report'
        , component: ScheduledReport
        , vertical:'ALC'},

    { path: '/alc/scheduled-report/edit'
        , exact: true
        , name: 'Edit Scheduled Report'
        , component: ScheduledReport
        , vertical:'ALC'},

    { path: '/ce/scheduled-reports/manage'
        , exact: true
        , name: 'Scheduled Reports'
        , component: ScheduledReports
        , vertical:'CE'},


    { path: '/ce/scheduled-reports/create'
        , exact: true
        , name: 'Create Scheduled Report'
        , component: ScheduledReport
        , vertical:'CE'},

    { path: '/ce/scheduled-report/edit'
        , exact: true
        , name: 'Edit Scheduled Report'
        , component: ScheduledReport
        , vertical:'CE'},

   { path: '/alc/alerts/manage'
     , exact: true
     , name: 'Alerts'
     , component: Alerts
       , vertical:'ALC' },

    {   path: '/alc/alert/create'
        , exact: true
        , name: 'Create Alert'
        , component: AlertComponent
        , vertical:'ALC' },
    
    {   path: '/alert/create'
        , exact: true
        , name: 'Create Alert'
        , component: AlertComponent
        , vertical:'Combine' },
    
    {   path: '/alert/edit'
        , exact: true
        , name: 'Edit Alert'
        , component: AlertComponent
        , vertical:'Combine' },

    { path: '/alerts/schedule'
        , exact: true
        , name: 'Schedule Alerts'
        , component: ScheduleAlerts
        , vertical:'Combine' },
    
    { path: '/alerts/manage'
        , exact: true
        , name: 'Alerts'
        , component: Alerts
        , vertical:'Combine' },

    {   path: '/alc/alert/edit'
        , exact: true
        , name: 'Edit Alert'
        , component: AlertComponent
        , vertical:'ALC' },

    { path: '/ce/alerts/schedule'
        , exact: true
        , name: 'Schedule Alerts'
        , component: ScheduleAlerts
        , vertical:'CE' },

    { path: '/alc/alerts/schedule'
        , exact: true
        , name: 'Schedule Alerts'
        , component: ScheduleAlerts
        , vertical:'ALC' },

    { path: '/ce/alerts/manage'
        , exact: true
        , name: 'Alerts'
        , component: Alerts
        , vertical:'CE' },

    {   path: '/ce/alert/create'
        , exact: true
        , name: 'Create Alert'
        , component: AlertComponent
        , vertical:'CE' },

    {   path: '/ce/alert/edit'
        , exact: true
        , name: 'Edit Alert'
        , component: AlertComponent
        , vertical:'CE' },

  //
  // { path: '/libraries'
  //   , exact: true, name: 'Libraries'
  //   , component: Libraries },

  {   path: '/home'
    , exact: true
    , name: ''
    , component: Home },

  {   path: '/report'
    , exact: true
    , name: 'Report'
    , component: PowerBI },

    {   path: '/basket/create'
        , exact: true
        , name: 'Create Basket'
        , component: CreateBasket },


    {   path: '/basket/edit'
        , exact: true
        , name: 'Edit Basket'
        , component: EditBasket },

  { path: '/reports/saved/report'
    , exact: true
    , name: 'Report'
    , component: PowerBI }  ,

    { path: '/reports/saved/report/:id'
        , exact: true
        , name: ''
        , component: PowerBI }  ,

  { path: '/reports/create/report'
    , exact: true
    , name: 'Report'
    , component: PowerBI },

  { path: '/campaigns/create'
    , exact: true, name: 'Create Campaign'
    , component: CreateCampaign },

  { path: '/campaigns/previous'
    , exact: true
    , name: 'Previous Campaigns'
    , component: Campaigns }  ,

  { path: '/campaigns/previous/performance'
    , exact: true
    , name: 'Performance'
    , component: PowerBI }  ,

  { path: '/api/tokens'
    , exact: true
    , name: 'Tokens'
    , component: Tokens }  ,

  { path: '/api/doc'
    , exact: true
    , name: 'API Documentation'
    , component: APIDocumentation }  ,

    { path: '/campaigns/create_demo'
        , exact: true, name: 'Create Campaign - Demo'
        , component: CreateCampaignDemo },

    { path: '/campaigns/previous_demo'
        , exact: true
        , name: 'Previous Campaigns - Demo'
        , component: CampaignsDemo }  ,






];

export default routes;
