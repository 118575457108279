import React from "react";
import insights_spinner from "../assets/insights_spinner.gif";


const LoadingInsights = () => (
  <div className="animated fadeIn spinner">
    <img src={insights_spinner} alt="Loading" />
  </div>
);

export default LoadingInsights;
