// helper.js
import axios from 'axios';

// CORS enabled apikey
const apikey = process.env.REACT_APP_RESTDB_API_KEY;

// REST endpoint
let restdb = axios.create({
  baseURL: process.env.REACT_APP_RESTDB_URL ,
  // 'https://settings-d365.restdb.io',
  timeout: 30000,
  headers: { 'x-apikey': apikey}
});

let pricing_restdb = axios.create({
    baseURL: process.env.REACT_APP_RESTDB_PRICING_URL ,
    // 'https://settings-d365.restdb.io',
    timeout: 30000,
    headers: { 'x-apikey': process.env.REACT_APP_RESTDB_PRICING_API_KEY}
});


export { apikey, restdb,pricing_restdb
};
